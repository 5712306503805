<template>
  <v-card>
    <v-container>
      <v-row dense class="mt-2">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Hae kaikista kentistä..."
            single-line
            hide-details
            solo
            @keyup.enter="getDataFromApi()"
            @click:append-outer="getDataFromApi()"
          ></v-text-field>
        </v-col>
        <v-col> </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="contacts"
      :footer-props="globalValues.footerProps"
      :items-per-page="getDefaultTableRows()"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalLength"
      item-key="_id"
      show-expand
      @item-expanded="readMessage"
      :item-class="unreadMessage"
    >
      <template #expanded-item="{ item }">
        <td :colspan="headers.length">
          <div class="mt-2 mb-1">
            <p style="white-space: pre">{{ item.message }}</p>
          </div>
        </td>
      </template>

      <!-- Apartment -->
      <template #[`item.apartment`]="{ item }">
        <span>{{ item.apartment.address }}</span>
        <span
          >{{ item.apartment.apartmentNumber ? `, ${item.apartment.apartmentNumber}` : "" }}
        </span>
      </template>

      <!-- CreatedAt -->
      <template #[`item.createdAt`]="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>

      <!-- Actions -->
      <template #[`item.actions`]="{ item }">
        <div class="action-grid-container">
          <!-- <v-icon title="Muokkaa" small @click="editItem(item)">mdi-pencil</v-icon> -->
          <v-icon
            v-if="isAuthorized('contact', 'delete')"
            title="Poista"
            small
            @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </div>
      </template>
      <template #no-data>
        <h2>Ei yhteydenottoja verkkosivuilta</h2>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";
import mixins from "../../mixins/mixins";

export default {
  title: "Yhteydenotot",

  mixins: [mixins],

  data: () => ({
    loading: true,
    options: {},
    dialog: false,
    search: "",
    headers: [
      { text: "Nimi", value: "name" },
      {
        text: "Puhelin",
        value: "phone",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Vuokrakohde",
        value: "apartment",
        sortable: false,
      },
      {
        text: "Vastaanotettu",
        value: "createdAt",
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("contact", ["contacts", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("contact", ["getAllContacts", "deleteContact", "markItemRead"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/contact/get-contacts?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getAllContacts(url);

      this.loading = false;
    },

    unreadMessage(item) {
      if (!item.read) {
        return "unread";
      }
    },

    readMessage(data) {
      if (!data.item.read) {
        setTimeout(() => this.markItemRead({ id: data.item._id, type: "contact" }), 800);
      }
    },

    editItem(item) {
      this.$router.push("/contact/edit/" + item._id);
    },

    deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa yhteydenoton: " + item.name))
        this.deleteContact(item._id);
    },
  },
};
</script>

<style scoped>
.v-data-table >>> table .unread {
  font-weight: 600;
}
</style>
