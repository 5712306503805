var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Hae kaikista kentistä...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append-outer":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col')],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.queueContacts,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength,"item-key":"_id","show-expand":"","item-class":_vm.unreadMessage},on:{"update:options":function($event){_vm.options=$event},"item-expanded":_vm.readMessage},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"mt-2 mb-1"},[_c('p',[_c('strong',[_vm._v("Viesti:")])]),_c('p',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(item.message))]),(item.allocation.length > 0)?_c('div',[_c('p',[_c('strong',[_vm._v("Tilan jako valinnat:")])]),_c('p',[_vm._v(_vm._s(item.allocation))])]):_vm._e(),(item.area.length > 0)?_c('div',[_c('p',[_c('strong',[_vm._v("Koko valinnat:")])]),_c('p',[_vm._v(_vm._s(item.area))])]):_vm._e()])])]}},{key:"item.apartment",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.apartment.address))]),_c('span',[_vm._v(_vm._s(item.apartment.apartmentNumber ? (", " + (item.apartment.apartmentNumber)) : "")+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('contact', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei yhteydenottoja verkkosivuilta")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }