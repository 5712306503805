<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Yhteydenotot</div>
    <v-container fluid style="padding: 0px; margin: 0px">
      <!-- TABS -->
      <!-- TABS -->
      <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="item in permissionTabs" :key="item.text" :href="`#${item.text}`">
          <div>{{ item.text }}</div>
        </v-tab>
      </v-tabs>
    </v-container>

    <!-- ContactTable -->
    <contact-table v-if="tab == 'Vuokrakohde'"></contact-table>
    <!-- ContactTableQueue -->
    <contact-table-queue v-if="tab == 'Jono'"></contact-table-queue>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import ContactTable from "../../components/Contacts/ContactTable.vue";
import ContactTableQueue from "../../components/Contacts/ContactTableQueue.vue";
import { mapState } from "vuex";

export default {
  title: "Yhteydenotot",

  components: {
    ContactTable,
    ContactTableQueue,
  },

  mixins: [mixins],

  data: () => ({
    tabs: [
      { text: "Vuokrakohde", permission: true },
      { text: "Jono", permission: false },
    ],
    tab: 0,
  }),

  computed: {
    ...mapState("account", ["currentUser"]),

    permissionTabs() {
      return this.tabs.filter((tab) => {
        if (tab.permission) {
          return true;
        } else {
          if (!this.hasAccountsConnected && tab.text == "Jono") return true;
        }
      });
    },

    hasAccountsConnected() {
      return this.currentUser?.currentAccount?.accountsConnected > 0;
    },
  },
};
</script>

<style scoped></style>
